<template>
    <div class="one-material__section hide-print-css" v-if="comments">
        <div class="one-material__head-row">
            <div v-if="headerOn" class="one-material__title">
                Комментарии <span v-if="countOn" class="one-material__prev-comment">({{comments.count}})</span>
            </div>
            <input
                v-if="!isEditor && subscriptionOn"
                :checked="subscription_id"
                type="checkbox" class="one-material__mark-ctrl hidden-ctrl" id="id_subscription">
            <label
                v-if="!isEditor && subscriptionOn"
                @click="subscription" class="one-material__mark-label one-material__mark-label_bad" for="id_subscription">
                <svg width="21" height="15" viewBox="0 0 21 15"><g><g><path fill="" d="M8.693 14.612L-.065 6.038l2.102-2.057 6.656 6.516L18.853.552l2.1 2.057z"/></g></g></svg>
                Подписаться на комментарии
            </label>
        </div>
        <comments :comments="comments" :obj-id="itemId" :update-comments-count="updateCommentsCount" :content-type="contentType"/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import Comments from "../wall/Comments/Comments";

    export default {
        name: "Comment",
        components: {
            Comments
        },
        props: {
            comments: {
                type: Object,
                required: false,
                default: []
            },
            itemId: {
                type: Number,
                required: true
            },
            contentType: {
                type: String,
                required: true
            },
            isEditor: {
                type: Boolean,
                default: false,
                required: false
            },
            subscriptionOn: {
                type: Boolean,
                default: false,
                required: false
            },
            headerOn: {
                type: Boolean,
                default: false,
                required: false
            },
            countOn: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        data() {
            return {
                subscription_id: null
            }
        },
        created() {
            if (this.itemId && this.subscriptionOn) {
                this.load()
            }
        },
        methods: {
            async updateCommentsCount(type) {
                const request = await session.get(`/api/v1/comment/count/?contentType=${type}&id=${this.itemId}`);
                this.comments.count = request.data.count
            },
            async load() {
                let response;
                try {
                    const request = session.get(`/api/v1/subscription-comment/signed_up/?object_id=${this.itemId}&content_type=${this.contentType}`);
                    response = await request;
                    this.subscription_id = response.data['id'];
                } catch (err) {
                }
            },
            async subscription() {
                if (this.subscription_id) {
                    let response;
                    try {
                        const request = session.delete(`/api/v1/subscription-comment/${this.subscription_id}/`);
                        response = await request;
                        this.subscription_id = null;
                    } catch (err) {
                        this.errorHandler(err);
                    }
                } else {
                    let response;
                    try {
                        const request = session.post('/api/v1/subscription-comment/', {
                            object_id: this.itemId, content_type: this.contentType
                        });
                        response = await request;
                        this.subscription_id = response.data['id'];
                    } catch (err) {
                        this.errorHandler(err);
                    }
                }
            },
            errorHandler(err) {
                console.error(err);
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: 'При обработке запроса произошла ошибка на сервере',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
                this.isLoading = false;
            },
        }
    }
</script>

<style lang="scss">


</style>
